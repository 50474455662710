<i18n>
ru:
  promoApplied: Добавлен в корзину
  promoButton: Применить промокод
  promoText: 'Ваш промокод:'
ua:
  promoApplied: Додано в кошик
  promoButton: Застосувати промокод
  promoText: 'Ваш промокод:'
us:
  promoApplied: Applied to cart
  promoButton: Apply promocode
  promoText: 'Your promocode:'
</i18n>

<template>
  <div class="v-promo-popup">
    <div
      v-if="imageExists(appConfig.VueSettingsPreRun.PromoPopupBanner)"
      class="v-promo-popup-img"
    >
      <arora-nuxt-image
        :alt="translate('promoInUrlPopup.promoText')"
        :image="appConfig.VueSettingsPreRun.PromoPopupBanner"
      />
    </div>
    <div
      v-else
      class="v-promo-popup-icon"
    >
      <icon-general-gift />
    </div>
    <div
      v-if="!stringIsNullOrWhitespace(title)"
      class="v-promo-popup-title"
      v-html="sanitize(title)"
    />
    <div
      v-else
      class="v-promo-popup-title"
      v-html="translate('promoInUrlPopup.promoText')"
    />
    <div
      v-if="!stringIsNullOrWhitespace(subtitle)"
      class="v-promo-popup-subtitle"
      v-html="sanitize(subtitle)"
    />
    <div
      class="v-promo-popup-code"
      :class="{ 'v-promo-popup-code-applied': applied }"
      v-html="decodeURIComponent(code)"
    />
    <cart-promo-code-apply-button
      :class-name="
        applied ? 'v-btn v-btn-lg v-btn-default v-w-100' : 'v-btn v-btn-primary v-btn-lg v-w-100'
      "
      :code="code"
      :label="translate(applied ? 'promoInUrlPopup.promoApplied' : 'promoInUrlPopup.promoButton')"
      :on-apply-callback="onApplyCallback"
    />
  </div>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

defineProps<{
  code: string
  title: string
  subtitle: string
}>()

const applied = ref<boolean>(false)

const popupStore = usePopupStore()

const appConfig = useAppConfig()
const { translate, sanitize } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const { imageExists } = useImageInfo()

function onApplyCallback(): void {
  applied.value = true

  setTimeout(() => {
    popupStore.closePopup()
  }, 500)
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-promo-popup {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 15px;

  &-icon {
    width: 50%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &-img {
    img {
      width: 100%;
      height: auto;
      border-radius: variables.$BorderRadius;
      overflow: hidden;
    }
  }

  &-title {
    font-size: variables.$TextSizeH2;
    font-weight: 600;
    text-align: center;
  }

  &-subtitle {
    font-size: variables.$TextSizeMain;
    text-align: center;
    color: variables.$BodyTextColorLight;
  }

  &-code {
    font-size: variables.$TextSizeH2;
    font-weight: 600;
    text-align: center;
    cursor: default;

    border-radius: variables.$BorderRadiusInput;
    padding: 10px 16px;
    background: variables.$OptionsBackground;
    color: variables.$PrimaryBackgroundColor;

    width: 100%;
    @include mixins.trans;

    &-applied {
      color: variables.$PopupColor;
    }
  }
}
</style>
